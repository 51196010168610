import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import { useProject } from "../../hooks/useProject";
import ImageViewer from "../ImageViewer";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Checkbox, Tooltip } from "@material-ui/core";
import { ExtraDetailsRow } from "./ExtraDetailsRow";

export function Row({
  row,
  index,
  isSelected,
  rowActions,
  headCells,
  onSelect,
  onEdit,
  loadData,
  isLoading,
}) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow hover key={index}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            onClick={() => {
              onSelect(row.id);
            }}
            checked={isSelected(row.id)}
            inputProps={{
              "aria-labelledby": `enhanced-table-checkbox-${index}`,
            }}
          />
        </TableCell>
        {headCells.map((headCell, index) => (
          <TableCell key={index} align={headCell.align}>
            <span
              className={headCell.getClassName && headCell.getClassName(row)}
            >
              {headCell.format ? headCell.format(row) : row[headCell.id]}
            </span>
          </TableCell>
        ))}
        <TableCell align="right" padding="none">
          {rowActions.map((action) =>
            action.hide && action.hide(row) ? (
              <></>
            ) : (
              <Tooltip title={action.tooltip}>
                <IconButton
                  size="small"
                  disabled={action.disabled && action.disabled(row)}
                  onClick={() => action.callback(row)}
                >
                  {action.icon}
                </IconButton>
              </Tooltip>
            )
          )}
        </TableCell>
      </TableRow>
      <ExtraDetailsRow
        open={open && !isLoading}
        row={row}
        headCells={headCells}
        onEdit={onEdit}
        loadData={loadData}
      />
    </React.Fragment>
  );
}
