import LinearProgress from "@material-ui/core/LinearProgress";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { useEffect, useState } from "react";
import { styles } from "./styles";

export const FileUploadProgress = (props) => {
  const classes = styles();
  const [progress, setProgress] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 10 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={classes.width}>
      <div className="file">
        <InsertDriveFileIcon className="file-icon" />
        <div className="file-content">
          <div className="file-name">{props.fileName}</div>
          <LinearProgress />
        </div>
      </div>
    </div>
  );
};
