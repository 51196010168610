import { CircularProgress } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import FormGroup from "@material-ui/core/FormGroup";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import Alert from "@material-ui/lab/Alert";
import React, { useCallback, useEffect, useState } from "react";
import { AutoComplete } from "../../../components/AutoComplete";
import { FormDialog } from "../../../components/FormDialog";
import { SCHEMA_SUB_TYPE } from "../../../constants";
import CommissioningService from "../services/Commissioning.service";
import ProjectService from "../services/Project.service";
import clientService from "../services/clientService";
import { ErrorMessages } from "./ErrorMessages";
import { FileUploadProgress } from "./FileUploadProgress";
import { SelectFileToUpload } from "./SelectFileToUpload";
import { StatusMessages } from "./StatusMessages";
import { styles } from "./styles";

let file = {};

const CommissioningDetails = ({ onChange, commissioningDetails }) => {
  const classes = styles();
  const [clientList, setClientList] = useState([]);
  const [projects, setProjects] = useState([]);
  const [schemas, setSchemas] = useState([]);
  const [clientListFetching, setClientListFetching] = useState(false);

  const [subAssetTypes, setSubAssetTypes] = useState([]);

  const handleOnChange = (name, value) => {
    onChange(name, value);
  };

  const getClients = useCallback(async () => {
    setClientListFetching(true);
    const clients = await clientService.getClients(true);
    setClientList(clients);
    setClientListFetching(false);
  }, []);

  const getProjects = async (clientId) => {
    const projects = clientId
      ? await ProjectService.getProjects({ clientId })
      : [];
    setProjects(projects);
  };

  const getSubAssetTypes = async (projectId) => {
    const subAssetTypes = projectId
      ? await ProjectService.getSubAssetTypes(undefined, projectId)
      : [];
    setSubAssetTypes(subAssetTypes);
  };

  const getSchemas = useCallback(async () => {
    const schemas = commissioningDetails?.subAssetTypeId
      ? await CommissioningService.getAllSchemas(
          commissioningDetails?.subAssetTypeId,
          SCHEMA_SUB_TYPE.Base
        )
      : [];
    setSchemas(schemas);
  }, [commissioningDetails?.subAssetTypeId]);

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    getProjects(commissioningDetails.clientId);
  }, [commissioningDetails.clientId]);

  useEffect(() => {
    getSubAssetTypes(commissioningDetails.projectId);
  }, [commissioningDetails.projectId]);

  useEffect(() => {
    getSchemas();
  }, [commissioningDetails.subAssetTypeId]);

  return (
    <div className={classes.width}>
      {clientListFetching ? (
        <Box textAlign={"center"}>
          <CircularProgress
            variant="indeterminate"
            disableShrink
            size={50}
            thickness={4}
          />
        </Box>
      ) : (
        <form>
          <FormGroup>
            <AutoComplete
              handleChange={(e) => handleOnChange("clientId", e?.id)}
              options={clientList}
              label="Client"
              value={clientList.find(
                (opt) => opt.id === commissioningDetails?.clientId
              )}
            />

            <AutoComplete
              handleChange={(e) => handleOnChange("projectId", e?.id)}
              options={projects}
              optionLabel="projectName"
              label="Project"
              value={projects.find(
                (opt) => opt.id === commissioningDetails?.projectId
              )}
            />
            <AutoComplete
              handleChange={(e) => handleOnChange("subAssetTypeId", e?.id)}
              options={subAssetTypes}
              optionLabel="subAssetType"
              label="Sub Asset Type"
              value={subAssetTypes.find(
                (opt) => opt.id === commissioningDetails?.subAssetTypeId
              )}
            />
            <AutoComplete
              handleChange={(e) => handleOnChange("schemaId", e?.id)}
              options={schemas}
              optionLabel="name"
              label="Schema"
              value={schemas.find(
                (opt) => opt.id === commissioningDetails?.schemaId
              )}
            />
            <div>
              {commissioningDetails.error && (
                <Alert className={classes.root} severity="error">
                  Please fill all the details
                </Alert>
              )}
            </div>
          </FormGroup>
        </form>
      )}
    </div>
  );
};

let isSavingToServer = false;

export default function AddCommissioningModal({ handleUploadFinished }) {
  const classes = styles();
  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = [
    "Enter Commissioning Details",
    "Select file to upload",
    "Uploading status",
  ];
  const [commissioningDetails, setCommissioningDetails] = React.useState("");
  const [fileName, setFileName] = React.useState("");
  const [errors, setErrors] = React.useState([]);

  const handleClickOpen = () => {
    isSavingToServer = false;
    setOpen(true);
  };

  const handleClose = () => {
    setActiveStep(0);
    setOpen(false);
    handleUploadFinished(true);
    setCommissioningDetails("");
  };

  const handleNext = () => {
    if (activeStep === 0) {
      if (
        !commissioningDetails.projectId ||
        !commissioningDetails.clientId ||
        !commissioningDetails.subAssetTypeId ||
        !commissioningDetails.schemaId
      ) {
        return setCommissioningDetails({
          ...commissioningDetails,
          error: true,
        });
      }
    }
    if (activeStep === 3 || activeStep === 4) {
      handleClose();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 3 || activeStep === 4) {
      setActiveStep(0);
      setOpen(false);
      handleUploadFinished(true);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const uploadFileToServer = async () => {
    try {
      isSavingToServer = true;

      let response = await CommissioningService.uploadCommissioningCsvFile(
        commissioningDetails,
        file
      );
      if (response && response.success) {
        isSavingToServer = false;
        setActiveStep(3);
      }
    } catch (err) {
      isSavingToServer = false;
      if (err.error) {
        setErrors(err);
      } else {
        setErrors(err.message);
      }
      setActiveStep(4);
    }
  };

  const onChange = (name, value) =>
    setCommissioningDetails({
      ...commissioningDetails,
      [name]: value,
      error: false,
    });

  const handleFileSelect = (event) => {
    file = event.target.files[0];
    setFileName(file.name);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <CommissioningDetails
            onChange={onChange}
            commissioningDetails={commissioningDetails}
          />
        );
      case 1:
        return (
          <SelectFileToUpload
            fileName={fileName}
            handleFileSelect={handleFileSelect}
          />
        );
      case 2:
        if (!isSavingToServer) {
          isSavingToServer = true;
          uploadFileToServer();
        }
        return <FileUploadProgress fileName={fileName} />;
      case 3:
        return <StatusMessages />;
      case 4:
        return <ErrorMessages errors={errors} />;
      default:
        return <StatusMessages />;
    }
  };

  return (
    <div>
      <Tooltip title="Upload Commissioning Data">
        <Fab
          color="primary"
          aria-label="add record"
          className={classes.margin}
          size="medium"
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      <FormDialog
        maxWidth="sm"
        title="Upload Commissioning Data"
        open={open}
        handleClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        showCloseButton={activeStep !== 2}
        showActionButtons={false}
      >
        <div className={classes.root}>
          <Stepper
            className={classes.stepperPadding}
            activeStep={activeStep}
            alternativeLabel
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            <div>
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
              <div className={classes.dialogActions}>
                {activeStep !== 3 && activeStep !== 4 && (
                  <Button
                    disabled={activeStep === 0 || activeStep === 2}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                )}
                <Button
                  variant="contained"
                  disabled={activeStep === 2}
                  color="primary"
                  onClick={handleNext}
                >
                  {activeStep === 3 || activeStep === 4 ? "Finish" : "Next"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </FormDialog>
    </div>
  );
}
