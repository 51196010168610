import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogTitle: {
    paddingLeft: theme.spacing(4),
  },
  closeButton: {
    position: "absolute",
    right: "20px",
  },
  dialogContent: {
    padding: "32px 0 16px",
  },
  dialogActions: {
    margin: 0,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: "10%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },
  margin: {
    marginRight: "12px",
  },
  stepperPadding: {
    padding: "8px 0 50px",
  },
  width: {
    width: "80%",
    margin: "0 auto 40px",
  },
}));
