import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";

const styles = makeStyles((theme) => ({
  dialogTitle: {
    paddingLeft: theme.spacing(4),
  },
  closeButton: {
    position: "absolute",
    right: "20px",
  },
  dialogContent: {
    padding: theme.spacing(4),
  },
  dialogActions: {
    margin: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },

  margin: {
    marginRight: "12px",
  },
}));

export const FormDialog = ({
  title,
  handleClose,
  open,
  children,
  handleSave,
  maxWidth = "xs",
  showCloseButton = true,
  showActionButtons = true,
}) => {
  const classes = styles();
  return (
    <Dialog
      fullWidth={true}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal"
    >
      <DialogTitle id="modal-title" className={classes.dialogTitle}>
        {title}
        {showCloseButton && (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent dividers className={classes.dialogContent}>
        {children}
      </DialogContent>
      {showActionButtons && (
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
