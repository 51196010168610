import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import { AutoComplete } from "../components/AutoComplete";
import { products } from "../constants";
import useAuth from "../hooks/useAuth";
import { useProject } from "../hooks/useProject";

const Header = () => {
  const { user, logoutRequest, isAdmin } = useAuth();
  const { selectedProduct, setSelectedProduct, isInstallation } = useProject();

  const onProductChange = (value) => {
    setSelectedProduct(value);
  };
  return (
    <div className="header">
      <img src={logo} alt="logo" />
      <div className="header-right-blc">
        <div className="nav">
          <div className="product-select">
            <AutoComplete
              value={selectedProduct}
              handleChange={onProductChange}
              options={products}
              size="small"
              disableClearable={true}
              noMargin
            />
          </div>
          <NavLink to="/dashboard">Home</NavLink>
          {isAdmin && (
            <>
              <NavLink to="/user">Users</NavLink>
              <NavLink to="/client">Clients</NavLink>
              {isInstallation ? (
                <NavLink to="/projects">Project Upload</NavLink>
              ) : (
                <NavLink to="/commissioning">Commissioning Upload</NavLink>
              )}
            </>
          )}
        </div>
        <div className="user-profile">
          <PersonOutlineIcon color="primary" />
          <div className="user-name">
            {user.firstName}
            <a className="log-out" onClick={logoutRequest}>
              LOGOUT
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
