import DateFnsUtils from "@date-io/date-fns";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { debounce, pick } from "lodash";
import { memo, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { getErrorMessage } from "../../utils/formUtils";

const getOptionsByDataSource = (dataSource, resources) => {
  return resources?.[dataSource]?.map((option) => (
    <MenuItem key={option.value} value={option.value}>
      {option.key}
    </MenuItem>
  ));
};


function convertToRegex(regexString) {
  
  // Remove leading and trailing slashes
  regexString = regexString.replace(/^\/|\/$/g, '');

  // Replace escaped characters
  regexString = regexString.replace(/\\(.)/g, '$1');

  // Return the converted regular expression
  return new RegExp(regexString);
}
 
const StringInput = ({ field, control, error }) => {
  if(field.validations?.pattern){
    field.validations.pattern.value=convertToRegex(field.validations.pattern.value.toString())
  }
  return (
    <Controller
      name={field.key}
      control={control}
      rules={{
        ...(field.validations || {}), 
      }}
      render={({ onChange, value }) => (
        <>
          <InputLabel size="small" style={{ marginBottom: "5px" }}>
            {field.label}
          </InputLabel>
          <TextField
            size="small"
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message || getErrorMessage(error?.type)}
            onChange={onChange}
            value={value}
            variant="outlined"
            style={{ width: "100%" }}
          />
        </>
      )}
    />
  );
};

const CalculatedField = ({ field, control, error, values, setValue }) => {
  const calculateResult = debounce((values) => {
    let formula = field?.formula;
    try {
      for (var key in values) {
        if (values.hasOwnProperty(key)) {
          var placeholder = new RegExp("\\b" + key + "\\b", "g");
          formula = formula.replace(placeholder, values[key]);
        }
      }

      setValue(field.key, eval(formula));
    } catch (error) {
      console.error("Error evaluating formula:", error);
    }
  }, 300);

  useEffect(() => {
    calculateResult(values);
    return calculateResult.cancel;
  }, [values, field?.formula, calculateResult]);

  return (
    <Controller
      name={field.key}
      control={control}
      rules={field.validations}
      render={({ onChange, value }) => (
        <>
          <InputLabel size="small" style={{ marginBottom: "5px" }}>
            {field.label}
          </InputLabel>
          <TextField
            size="small"
            error={Boolean(error)}
            helperText={Boolean(error) && error}
            onChange={onChange}
            value={value}
            disabled={true}
            variant="outlined"
            style={{ width: "100%" }}
          />
        </>
      )}
    />
  );
};

const DateInput = ({ field, control, error }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Controller
        name={field.key}
        control={control}
        rules={field.validations}
        render={({ onChange, value }) => (
          <>
            <InputLabel size="small" style={{ marginBottom: "5px" }}>
              {field.label}
            </InputLabel>
            <TextField
              size="small"
              error={Boolean(error)}
              helperText={Boolean(error) && error}
              onChange={onChange}
              value={value}
              variant="outlined"
              style={{ width: "100%" }}
              type="date"
            />
          </>
        )}
      />
    </MuiPickersUtilsProvider>
  );
};

const FormSelect = ({ field, control, error }) => (
  <Controller
    name={field.key}
    control={control}
    rules={field.validations}
    render={({ onChange, value }) => (
      <>
        <InputLabel style={{ marginBottom: "5px" }}>{field.label}</InputLabel>
        <FormControl size="small" fullWidth>
          <Select
            fullWidth
            size="small"
            style={{ textAlign: "left" }}
            variant="outlined"
            error={Boolean(error)}
            helperText={Boolean(error) && error}
            value={value}
            onChange={onChange}
          >
            {field?.options?.map((option) => (
              <MenuItem key={option.key} value={option.value}>
                {option.key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    )}
  />
);

const RadioComponent = ({ field, control, error }) => (
  <Controller
    name={field.key}
    control={control}
    rules={field.validations}
    render={({ onChange, value }) => (
      <FormControl>
        <FormLabel id="demo-controlled-radio-buttons-group">
          {field.label}
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={onChange}
        >
          {field?.options?.map((option) => (
            <FormControlLabel
              value={option.value}
              control={<Radio color="primary" />}
              label={option.key}
            />
          ))}
        </RadioGroup>
        {Boolean(error) && (
          <FormHelperText style={{ color: "red" }}>{error}</FormHelperText>
        )}
      </FormControl>
    )}
  />
);

const CheckBoxComponent = ({ field, control, error }) => {
  const handleCheckboxChange = (optionKey, isChecked, value, onChange) => {
    let updatedItems;
    if (isChecked) {
      // Add the selected item to the array
      updatedItems = value?.length ? [...value, optionKey] : [optionKey];
    } else {
      // Remove the unselected item from the array
      updatedItems = value.filter((item) => item !== optionKey);
    }
    onChange(updatedItems);
  };

  return (
    <Controller
      name={field.key}
      control={control}
      rules={field.validations}
      render={({ onChange, value }) => (
        <FormControl>
          <FormLabel size="small">{field.label}</FormLabel>

          {field?.options?.map((option) => (
            <FormControlLabel
              key={option.key}
              checked={value?.length && value.includes(option.value)}
              control={<Checkbox color="primary" />}
              label={option.key}
              onChange={(e) =>
                handleCheckboxChange(
                  option.value,
                  e.target.checked,
                  value,
                  onChange
                )
              }
            />
          ))}

          {Boolean(error) && (
            <FormHelperText style={{ color: "red" }}>{error}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

const FormFieldV2 = ({ field }) => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const regex = /\b([a-zA-Z]+)\b(?![^{]*})/g;
  const values = field.formula && watch(field?.formula.match(regex));

  switch (field.formField) {
    case "input":
    case "qrInput":
      return (
        <>
          {field.formula ? (
            <CalculatedField
              field={field}
              control={control}
              error={getErrorMessage(errors?.[field?.key]?.type)}
              setValue={setValue}
              values={values}
            />
          ) : (
            <StringInput
              field={field}
              control={control}
              error={errors?.[field?.key]}
            />
          )}
        </>
      );
    case "date":
      return (
        <DateInput
          field={field}
          control={control}
          error={getErrorMessage(errors?.[field?.key]?.type)}
        />
      );
    case "dropDown":
      return (
        <FormSelect
          field={field}
          control={control}
          error={getErrorMessage(errors?.[field?.key]?.type)}
        />
      );
    case "radio":
      return (
        <RadioComponent
          field={field}
          control={control}
          error={getErrorMessage(errors?.[field?.key]?.type)}
        />
      );
    case "checkbox":
      return (
        <CheckBoxComponent
          field={field}
          control={control}
          error={getErrorMessage(errors?.[field?.key]?.type)}
        />
      );

    default:
      return <>to be rendered {field.formField}</>;
  }
};

export default memo(FormFieldV2);
