import { styles } from "./styles";
import Alert from "@material-ui/lab/Alert";

export const StatusMessages = () => {
  const classes = styles();

  return (
    <div className={classes.root}>
      <div className="upload-status-msg">
        <Alert severity="success">File uploaded successfully.!</Alert>
      </div>
    </div>
  );
};
