import { CircularProgress } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import FormGroup from "@material-ui/core/FormGroup";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import Alert from "@material-ui/lab/Alert";
import React, { useCallback, useEffect, useState } from "react";
import { AutoComplete } from "../../../components/AutoComplete";
import { FormDialog } from "../../../components/FormDialog";
import ProjectService from "../services/Project.service";
import clientService from "../services/clientService";
import { ErrorMessages } from "./ErrorMessages";
import { FileUploadProgress } from "./FileUploadProgress";
import { SelectFileToUpload } from "./SelectFileToUpload";
import { StatusMessages } from "./StatusMessages";
import { styles } from "./styles";

function getSteps() {
  return ["Enter Project Details", "Select file to upload", "Uploading status"];
}

let file = {};

const ProjectDetails = ({ onChange, projectDetails }) => {
  const classes = styles();
  const [clientList, setClientList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [schemas, setSchemas] = useState([]);
  const [clientListFetching, setClientListFetching] = useState(false);
  const [categoriesFetching, setCategoriesFetching] = useState(false);
  const [schemasFetching, setSchemasFetching] = useState(false);

  const handleOnChange = (name, value) => {
    onChange(name, value);
  };

  const getClients = useCallback(async () => {
    setClientListFetching(true);
    const clients = await clientService.getClients(true);
    setClientList(clients);
    setClientListFetching(false);
  }, []);

  const getCategories = useCallback(async () => {
    setCategoriesFetching(true);
    const categories = await ProjectService.getAllCategories();
    setCategories(categories);
    setCategoriesFetching(false);
  }, []);

  const getSchemas = useCallback(async () => {
    setSchemasFetching(true);
    const schemas = await ProjectService.getAllSchemas();
    setSchemas(schemas);
    setSchemasFetching(false);
  }, []);

  useEffect(() => {
    getClients();
    getCategories();
    getSchemas();
  }, []);

  return (
    <div className={classes.width}>
      {clientListFetching || categoriesFetching || schemasFetching ? (
        <Box textAlign={"center"}>
          <CircularProgress
            variant="indeterminate"
            disableShrink
            size={50}
            thickness={4}
          />
        </Box>
      ) : (
        <form>
          <FormGroup>
            <Box mb={2.5}>
              <TextField
                value={projectDetails?.projectName}
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                id="projectNameModal"
                label="Project name"
                variant="outlined"
                name="projectName"
                className={classes.root}
              />
            </Box>

            <AutoComplete
              handleChange={(e) => handleOnChange("clientId", e?.id)}
              options={clientList}
              label="Client"
              value={clientList.find(
                (opt) => opt.id === projectDetails?.clientId
              )}
            />

            <AutoComplete
              handleChange={(e) => handleOnChange("categoryId", e?.id)}
              options={categories}
              optionLabel="assetCategory"
              label="Category"
              value={categories.find(
                (opt) => opt.id === projectDetails?.categoryId
              )}
            />
            <AutoComplete
              handleChange={(e) => handleOnChange("schemaId", e?.id)}
              options={schemas}
              optionLabel="name"
              label="Schema"
              value={schemas.find((opt) => opt.id === projectDetails?.schemaId)}
            />
            {projectDetails.error && (
              <Alert className={classes.root} severity="error">
                Please fill all the details
              </Alert>
            )}
          </FormGroup>
        </form>
      )}
    </div>
  );
};

let isSavingToServer = false;

export default function AddProjectModal({ handleUploadFinished }) {
  const classes = styles();
  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [projectDetails, setProjectDetails] = React.useState("");
  const [fileName, setFileName] = React.useState("");
  const [errors, setErrors] = React.useState([]);

  const handleClickOpen = () => {
    isSavingToServer = false;
    setOpen(true);
  };

  const handleClose = () => {
    setActiveStep(0);
    setOpen(false);
    handleUploadFinished(true);
    setProjectDetails("");
  };

  const handleNext = () => {
    if (activeStep === 0) {
      if (
        !projectDetails.projectName ||
        !projectDetails.clientId ||
        !projectDetails.categoryId
      ) {
        return setProjectDetails({ ...projectDetails, error: true });
      }
    }
    if (activeStep === 3 || activeStep === 4) {
      handleClose();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 3 || activeStep === 4) {
      setActiveStep(0);
      setOpen(false);
      handleUploadFinished(true);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const uploadFileToServer = async () => {
    try {
      isSavingToServer = true;

      let response = await ProjectService.uploadProjectCsvFile(
        projectDetails,
        file
      );
      if (response && response.data && response.success) {
        isSavingToServer = false;
        setActiveStep(3);
      }
    } catch (err) {
      isSavingToServer = false;
      if (err.error) {
        setErrors(err.error);
      } else {
        setErrors(err.message);
      }
      setActiveStep(4);
    }
  };

  const onChange = (name, value) =>
    setProjectDetails({ ...projectDetails, [name]: value, error: false });

  const handleFileSelect = (event) => {
    file = event.target.files[0];
    setFileName(file.name);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <ProjectDetails onChange={onChange} projectDetails={projectDetails} />
        );
      case 1:
        return (
          <SelectFileToUpload
            fileName={fileName}
            handleFileSelect={handleFileSelect}
          />
        );
      case 2:
        if (!isSavingToServer) {
          isSavingToServer = true;
          uploadFileToServer();
        }
        return <FileUploadProgress fileName={fileName} />;
      case 3:
        return <StatusMessages />;
      case 4:
        return <ErrorMessages errors={errors} />;
      default:
        return <StatusMessages />;
    }
  };

  return (
    <div>
      <Tooltip title="Upload Project Data">
        <Fab
          color="primary"
          aria-label="add record"
          className={classes.margin}
          size="medium"
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      <FormDialog
        maxWidth="sm"
        title="Upload Project Data"
        open={open}
        handleClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        showCloseButton={activeStep !== 2}
        showActionButtons={false}
      >
        <div className={classes.root}>
          <Stepper
            className={classes.stepperPadding}
            activeStep={activeStep}
            alternativeLabel
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            <div>
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
              <div className={classes.dialogActions}>
                {activeStep !== 3 && activeStep !== 4 && (
                  <Button
                    disabled={activeStep === 0 || activeStep === 2}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                )}
                {(activeStep === steps) !== 2 && (
                  <Button
                    variant="contained"
                    disabled={activeStep === 2}
                    color="primary"
                    onClick={handleNext}
                  >
                    {activeStep === 3 || activeStep === 4 ? "Finish" : "Next"}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </FormDialog>
    </div>
  );
}
