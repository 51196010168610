import { Box, Divider } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
} from "react";
import { useProject } from "../../../hooks/useProject";

import { useState } from "react";
import FormDetailsFrom from "../../../components/Form/FormDetailsFrom";
import OtherDetailsForm from "../../../components/Form/OtherDetailsForm";
import assetService from "../services/assetService";
import CommissioningService from "../services/Commissioning.service";

const styles = makeStyles((theme) => ({
  dialogTitle: {
    paddingLeft: theme.spacing(4),
  },
  closeButton: {
    position: "absolute",
    right: "20px",
  },
  dialogActions: {
    margin: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const Modal = ({ pentrationData, loadPenetrations }, ref) => {
  const classes = styles();
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("md");
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [resources, setResources] = useState({});
  const [schema, setSchema] = useState();
  const [alertData, setAlertData] = useState({
    title: "success",
    type: "success",
    message: "success",
  });
  const [formType, setFormType] = useState(null);

  const isEdit = pentrationData && pentrationData.id;

  useImperativeHandle(ref, () => ({
    handleClickOpen(formType) {
      handleClickOpen(formType);
    },
  }));

  const handleClickOpen = (formType) => {
    setFormType(formType);
  };

  const {
    selectedProject,
    getSchemaBySubAssetTypeId,
    commissioningFormSchema,
  } = useProject();

  const getSchema = async () => {
    try {
      const data = await assetService.fetchSchema(
        selectedProject.id,
        pentrationData?.subAssetTypeId
      );
      const { resources, ...schema } = data;

      setSchema(schema);
      setResources(data?.resources);
    } catch (error) {
      setAlertData({
        title: "Error",
        type: "error",
        message: "Something went wrong",
      });
      setSnackBarOpen(true);
    }
  };

  const handleFormSubmit = async (data) => {
    try {
      let response = {};
      switch (formType) {
        case "formDetails":
          response = await assetService.updateFormDetails(
            {
              formDetails: data,
            },
            pentrationData.id
          );
          break;
        case "otherDetails":
          response = await assetService.updatePenetration({
            id: pentrationData.id,
            ...data,
          });
          break;
        case "commissioningDetails":
          response = await CommissioningService.updateCommissioningDetails({
            commissioningId: pentrationData.commissioningData?.id,
            ...data,
          });
          break;
        case "commissioningFormDetails":
          response = await CommissioningService.updateFormDetails(
            {
              formDetails: data,
            },
            pentrationData.commissioningData?.id
          );
          break;
        default:
          // Handle default case if needed
          break;
      }

      if (response && response.data && response.success) {
        setAlertData({
          title: "Success",
          type: "success",
          message: `Successfully ${isEdit ? "updated" : "created"} the asset`,
        });
        loadPenetrations();

        setSnackBarOpen(true);
        setFormType(null);
        return response.data;
      }
    } catch (error) {
      setAlertData({
        title: "Error",
        type: "error",
        message: "Something went wrong",
      });
      setSnackBarOpen(true);
      throw error;
    }
  };

  useEffect(() => {
    pentrationData?.subAssetTypeId && getSchema();
  }, [pentrationData?.subAssetTypeId]);

  const formJson = useMemo(() => {
    // eslint-disable-next-line default-case
    switch (formType) {
      case "otherDetails":
        return schema?.excelSchema;
      case "formDetails":
        return schema?.formSchema;
      case "images":
        return schema?.imageSchema;
      case "commissioningDetails":
        const commissioningSchema = getSchemaBySubAssetTypeId(
          pentrationData?.subAssetTypeId
        );
        return commissioningSchema.find((x) => x.subType === "BASE")?.schema;
      case "commissioningFormDetails":
        const commissioningFormSchema = getSchemaBySubAssetTypeId(
          pentrationData?.subAssetTypeId
        );
        return commissioningFormSchema.find((x) => x.subType === "FORM")
          ?.schema;
    }
  }, [
    formType,
    schema,
    commissioningFormSchema,
    pentrationData?.subAssetTypeId,
  ]);

  const renderForms = () => {
    switch (formType) {
      case "otherDetails":
        return (
          <OtherDetailsForm
            formJson={formJson}
            resources={resources}
            data={{
              ...pentrationData.project,
              ...pentrationData.otherDetails,
              ...pentrationData,
            }}
            isEdit={true}
            handleFormSubmit={handleFormSubmit}
            handleFormCancel={() => setFormType(null)}
          />
        );
      case "commissioningDetails":
        return (
          <OtherDetailsForm
            formJson={formJson}
            data={{
              ...pentrationData.commissioningData?.data,
              assetId: pentrationData?.assetId,
              status: pentrationData.commissioningData?.status,
            }}
            isEdit={true}
            handleFormSubmit={handleFormSubmit}
            handleFormCancel={() => setFormType(null)}
          />
        );
      case "formDetails":
        return (
          <FormDetailsFrom
            formJson={formJson}
            resources={resources}
            data={{
              ...pentrationData.project,
              ...pentrationData.formDetails,
              ...pentrationData,
            }}
            isEdit={true}
            handleFormSubmit={handleFormSubmit}
            handleFormCancel={() => setFormType(null)}
          />
        );
      case "commissioningFormDetails":
        return (
          <FormDetailsFrom
            formJson={formJson}
            resources={resources}
            data={pentrationData.commissioningData?.formData}
            isEdit={true}
            handleFormSubmit={handleFormSubmit}
            handleFormCancel={() => setFormType(null)}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={formType}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setFormType(null);
          }
        }}
        aria-labelledby="modal"
      >
        <DialogTitle id="modal-title" className={classes.dialogTitle}>
          {!isEdit ? "Add New" : "Edit"} Record
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => setFormType(null)}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <Box p={4}>{renderForms()}</Box>
      </Dialog>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={1000}
        onClick={() => setFormType(null)}
        onClose={() => setSnackBarOpen(false)}
      >
        <Alert
          onClick={() => setFormType(null)}
          severity={alertData.type}
          variant="filled"
        >
          <AlertTitle>{alertData.title}</AlertTitle>
          {alertData.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default forwardRef(Modal);
