export const USER_ROLE = {
  ADMIN: "ADMIN",
};

export const products = [
  { id: "INSTALLATION", name: "Installation" },
  { id: "COMMISSIONING", name: "Commissioning" },
];

export const SCHEMA_TYPE = {
  COMMISSIONING_SCHEMA: "COMMISSIONING_SCHEMA",
  PROJECT_SCHEMA: "PROJECT_SCHEMA",
  COMPONENT_SCHEMA: "COMPONENT_SCHEMA",
};

export const SCHEMA_SUB_TYPE = {
  Base: "Base",
  Form: "Form",
  Image: "Image",
};
