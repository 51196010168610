import { useState } from "react";
import { styles } from "./styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

export const SelectFileToUpload = (props) => {
  const classes = styles();
  const [fileName, setFileName] = useState("");

  const uploadFile = () => {
    document.getElementById("selectExcelFile").click();
  };

  const onFileSelection = (event) => {
    props.handleFileSelect(event);
    setFileName(event.target.files[0].name);
  };

  return (
    <div className={classes.width}>
      {!fileName && (
        <div onClick={uploadFile} className="file-upload-btn">
          <CloudUploadIcon fontSize="large" className="file-upload-icon" />
          <div className="small-text">Click here to attached the file...</div>
          <input
            id="selectExcelFile"
            type="file"
            hidden
            onChange={(e) => onFileSelection(e)}
          />
        </div>
      )}

      {fileName && (
        <div onClick={uploadFile} className="file-upload-btn">
          <div className="small-text">{fileName}</div>
          <input
            id="selectExcelFile"
            type="file"
            hidden
            onChange={(e) => onFileSelection(e)}
          />
        </div>
      )}
    </div>
  );
};
