import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  Checkbox,
  LinearProgress,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Row } from "./Row";
import { NoRecords } from "../NoRecords";

export default function CollapsibleTable({
  headCells,
  checked,
  indeterminate,
  onSelectAll,
  onSelect,
  isLoading,
  rows,
  selectedRecords,
  rowActions,
  onEdit,
  loadData,
}) {
  const isSelected = (name) => selectedRecords.indexOf(name) !== -1;
  return (
    <TableContainer>
      {isLoading && (
        <LinearProgress className={"loading-bar"} color="primary" />
      )}
      <Table aria-label="collapsible table" size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={indeterminate}
                checked={checked}
                onChange={onSelectAll}
                inputProps={{ "aria-label": "select all asserts" }}
              />
            </TableCell>
            {headCells.map((headCell) => (
              <TableCell key={headCell.id} align={headCell.align}>
                {headCell.label}
              </TableCell>
            ))}
            <TableCell align="right" padding="none" />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length ? (
            rows.map((row, index) => (
              <Row
                {...{
                  row,
                  index,
                  isSelected,
                  rowActions,
                  headCells,
                  onSelect,
                  onEdit,
                  loadData,
                  isLoading,
                }}
              />
            ))
          ) : (
            <TableRow hover>
              <TableCell align="center" colSpan={headCells.length + 2}>
                <Typography variant="subtitle2">
                  No Data to Display. Please adjust the filters.
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
