import { styles } from "./styles";
import Alert from "@material-ui/lab/Alert";

export const ErrorMessages = ({ errors }) => {
  const classes = styles();

  const createTextFile = (errors) => {
    let sentence = "";

    if (Array.isArray(errors) && errors) {
      errors?.forEach((text) => {
        sentence = `${sentence} Row ${text.row}:\n`;
        text.details.forEach((det) => (sentence = `${sentence}   - ${det} \n`));
        sentence = `${sentence} \n`;
      });
    }

    const element = document.createElement("a");
    const file = new Blob([sentence], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "error.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <div className={classes.root}>
      <div className="upload-status-msg">
        {errors?.error && Array.isArray(errors?.error) ? (
          <>
            <Alert severity="error">File upload failed! {errors.message}</Alert>
            <a onClick={() => createTextFile(errors?.error)}>{"error.txt"}</a>
          </>
        ) : (
          <>
            <Alert severity="error">
              <p>File upload failed!</p>
              <p>{errors}</p>
            </Alert>
          </>
        )}
      </div>
    </div>
  );
};
