import { CommissioningEndPoints } from "../../../config";
import axios from "../../../utils/axios";

const getAllSchemas = async (subAssetTypeId, subSchemaType) => {
  try {
    let url = CommissioningEndPoints.schema;
    if (subAssetTypeId && subSchemaType) {
      url = url.concat(
        `?subAssetTypeId=${subAssetTypeId}&type=${subSchemaType}`
      );
    }

    const response = await axios.get(url);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const uploadCommissioningCsvFile = async (commissioningDetails, file) => {
  try {
    let url = CommissioningEndPoints.upload;
    url = url.replace("{projectId}", commissioningDetails.projectId);
    url = url.replace("{clientId}", commissioningDetails.clientId);
    url = url.replace("{schemaId}", commissioningDetails.schemaId);
    url = url.replace("{subAssetTypeId}", commissioningDetails.subAssetTypeId);
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(url, formData);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw error.response.data;
    }
    throw error;
  }
};

const getCommissioningData = async (data) => {
  try {
    let url = CommissioningEndPoints.assetData;

    const response = await axios.post(url, data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const updateCommissioningDetails = async (data) => {
  try {
    let url = CommissioningEndPoints.commissioning;

    const response = await axios.put(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateFormDetails = async (data, commissioningId) => {
  try {
    let url = CommissioningEndPoints.formDetails;
    url = url.replace("{commissioningId}", commissioningId);

    const response = await axios.put(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default {
  getAllSchemas,
  uploadCommissioningCsvFile,
  getCommissioningData,
  updateCommissioningDetails,
  updateFormDetails,
};
